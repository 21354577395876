/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CompleteRegistrationRequest } from '../models/complete-registration-request';
import { LoginResponse } from '../models/login-response';
import { RegistrationRequest } from '../models/registration-request';
import { VerifyRegistrationResponse } from '../models/verify-registration-response';

@Injectable({
  providedIn: 'root',
})
export class SwaggerRegistrationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation registrationControllerRegister
   */
  static readonly RegistrationControllerRegisterPath = '/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationControllerRegister()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registrationControllerRegister$Response(params: {
      body: RegistrationRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SwaggerRegistrationService.RegistrationControllerRegisterPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registrationControllerRegister$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registrationControllerRegister(params: {
      body: RegistrationRequest
  }): Observable<void> {

    return this.registrationControllerRegister$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation registrationControllerCompleteRegistration
   */
  static readonly RegistrationControllerCompleteRegistrationPath = '/register/complete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationControllerCompleteRegistration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registrationControllerCompleteRegistration$Response(params: {
      body: CompleteRegistrationRequest
  }): Observable<StrictHttpResponse<LoginResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SwaggerRegistrationService.RegistrationControllerCompleteRegistrationPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registrationControllerCompleteRegistration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registrationControllerCompleteRegistration(params: {
      body: CompleteRegistrationRequest
  }): Observable<LoginResponse> {

    return this.registrationControllerCompleteRegistration$Response(params).pipe(
      map((r: StrictHttpResponse<LoginResponse>) => r.body as LoginResponse)
    );
  }

  /**
   * Path part for operation mailVerificationControllerVerify
   */
  static readonly MailVerificationControllerVerifyPath = '/verify/{token}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mailVerificationControllerVerify()` instead.
   *
   * This method doesn't expect any request body.
   */
  mailVerificationControllerVerify$Response(params: {
    token: string;

  }): Observable<StrictHttpResponse<VerifyRegistrationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SwaggerRegistrationService.MailVerificationControllerVerifyPath, 'get');
    if (params) {

      rb.path('token', params.token, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VerifyRegistrationResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mailVerificationControllerVerify$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mailVerificationControllerVerify(params: {
    token: string;

  }): Observable<VerifyRegistrationResponse> {

    return this.mailVerificationControllerVerify$Response(params).pipe(
      map((r: StrictHttpResponse<VerifyRegistrationResponse>) => r.body as VerifyRegistrationResponse)
    );
  }

}
