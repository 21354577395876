import { computeStartOfLinePositions } from '@angular/compiler-cli/src/ngtsc/sourcemaps/src/source_file';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { of, ReplaySubject, Subject } from 'rxjs';

import { CompleteRegistrationRequest, LoginResponse, RegistrationRequest } from '@swagger/models';
import { SwaggerAuthenticationService, SwaggerRegistrationService } from '@swagger/services';

// TODO https://blog.angular-university.io/angular-jwt-authentication/
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public error$: Subject<string> = new ReplaySubject<string>(1);
    public success$: Subject<string> = new ReplaySubject<string>(1);

    constructor(
        private swaggerRegistrationService: SwaggerRegistrationService,
        private swaggerAuthService: SwaggerAuthenticationService,
        private router: Router
    ) {}

    public async startRegistration(req: RegistrationRequest): Promise<void> {
        try {
            const result = await this.swaggerRegistrationService
                .registrationControllerRegister({ body: req })
                .toPromise();
            this.success$.next('success.registration.mail');
        } catch (error) {
            this.error$.next(JSON.parse(error.error).message);
        }
    }

    public async completeRegistration(req: CompleteRegistrationRequest): Promise<void> {
        this.swaggerRegistrationService.registrationControllerCompleteRegistration({ body: req }).subscribe(
            (result) => {
                this.setSession(result);
            },
            (error) => {
                this.error$.next(error.error.message);
            },
            () => {
                this.router.navigateByUrl('/home');
            }
        );
    }

    public async login(username: string, pwd: string): Promise<void> {
        try {
            const result = await this.swaggerAuthService
                .authControllerLogin({ body: { email: username, password: pwd } })
                .toPromise();
            this.setSession(result);
            await this.router.navigateByUrl('/home');
        } catch (error) {
            this.error$.next('error.login.mismatch');
        }
    }

    public getAccessToken(): string {
        return localStorage.getItem('id_token');
    }

    private setSession(loginResponse: LoginResponse) {
        localStorage.setItem('id_token', loginResponse.access_token);
    }

    public logout() {
        localStorage.removeItem('id_token');
        this.router.navigateByUrl('/auth/login');
    }
}
