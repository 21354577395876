import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';

import { AuthService } from '@core/services/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanLoad {
    constructor(private router: Router, private readonly authService: AuthService) {}

    canLoad(route: Route): boolean {
        if (this.authService.getAccessToken()) {
            return true;
        }

        this.router.navigateByUrl('/auth/login');
    }
}
