import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { environment } from 'src/environments/environment';

import { AuthGuard } from '@core/guards/auth/auth.guard';
import { ApiInterceptor } from '@core/interceptors/api/api.interceptor';
import { ApiModule } from '@swagger/api.module';

@NgModule({
    providers: [
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true,
        },
    ],
    imports: [HttpClientModule, CommonModule, ApiModule.forRoot({ rootUrl: environment.apiUrl })],
    exports: [ApiModule],
})
export class CoreModule {}
