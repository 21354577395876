import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from '@core/services/auth/auth.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const responseType = request.responseType || 'json';
        const apiToken = this.authService.getAccessToken();
        let requestWithHeaders: HttpRequest<any>;
        if (apiToken) {
            requestWithHeaders = request.clone({
                headers: request.headers.set('Authorization', 'Bearer ' + apiToken),
                responseType,
            });
        } else {
            requestWithHeaders = request.clone({
                responseType,
            });
        }

        return next.handle(requestWithHeaders);
    }
}
